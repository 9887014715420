.App {
    text-align: center;
}

main.main-style {
    background-color: #27272efd;
    height: 1200px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#footer {
    position: fixed;
    display: 'flex';
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    text-align: 'center';
    background-color: #020f1b;
    z-index: 1000;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    max-height: 50px;
}

.mock {
    color: #dae0e0;
}

#headerBar {
    top: 0px;
    width: 100%;
    background-color: #020f1b;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

#appName {
    text-align: center;
    font-weight: bolder;

    color: aliceblue;
    margin: 0;
    padding: 3px;
}

#mypage {
    display: table-cell;
    position: absolute;
    right: 0;
    top: 6px;
    margin: 0;
}

.popup-menu {
    position: absolute;
    z-index: 2;

    width: 10rem;
    margin-top: 0.5rem;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;
}

#accountTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#accountTable th {
    width: 30%;
    font-size: 10pt;
    border-right: 3px solid rgb(234, 245, 255);
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    color: #dae0e0;
    font-weight: bold;
}
#accountTable td {
    width: 50%;
    color: #dae0e0;
    font-weight: bold;
    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}

#timeTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#timeTable th {
    width: 40%;
    font-size: 10pt;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    color: #fff;
}
#timeTable td {
    width: 50%;
    color: #fff;

    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}

#centeredWrapper {
    text-align: 'center';
}

#linkedText {
    color: #bec8e9;
    text-decoration: underline;
}
