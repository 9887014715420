.text-image {
    position: relative;
}

.text-image img {
    width: 100%;
}

.text-image p {
    position: absolute;
    top: 38%;
    left: 17%;
    font-size: 30px;
    color: #000080;
}

#videoBasic {
    margin-top: 30px;
    width: 80%;
    height: 40%;
}

/* .text-image p #stride{
  position: absolute;
  top: 40%;
  left: 35%;
  font-size: 30px;
  color: #000080;
} */

.recharts-surface {
    background-color: #022130;
}
