.text-image {
    position: relative;
}

.text-image img {
    width: 100%;
}

.text-image p {
    position: absolute;
    top: 38%;
    left: 17%;
    font-size: 30px;
    color: #000080;
}

#videoBasic {
    margin-top: 30px;
    width: 90%;
    height: 45%;
}

#detailTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#detailTable th {
    width: 45%;
    font-size: 9pt;
    border-right: 3px solid rgb(253, 253, 253);
    border-top: 1px solid rgb(97, 149, 201);
    border-bottom: 1px solid rgb(97, 149, 201);
    padding: 6px;
    color: white;
}
#detailTable td {
    width: 45%;
    font-size: 10pt;
    border-top: 1px solid rgb(97, 149, 201);
    border-bottom: 1px solid rgb(97, 149, 201);
    padding: 6px;
    color: white;
}

#timeChar {
    font-family: 'Courgette', cursive;
    font-size: 30pt;
    color: white;
    border-bottom: solid 1px #66cdaa;
}
