#white-char {
    color: rgba(255, 255, 255, 0.815);
    font-weight: bold;
    font-size: 8pt;
}

#blurred {
    box-shadow: 5px 5px 5px 0 rgba(201, 201, 201, 0.522);
}

#videoCard {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    width: 90%;
    text-align: center;
    padding: 7px;
    padding-bottom: 3px;
    margin-top: 3px;
    margin-left: 10px;
    border-bottom: solid 2px #9ba2a7;
}
#videoCard:hover {
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px) translateX(-8px);
}
