label.inputwrapper {
    background: #1e90ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
    font-weight: bolder;
}
label input {
    display: none;
}

#white-char {
    font-size: 12pt;
    color: rgba(255, 255, 255, 0.842);
}

.select {
    color: rgb(61, 63, 65);
}

.MuiSelect-nativeInput {
    color: aliceblue;
}

.MuiInputBase-inputMultiline {
    height: auto;
    resize: none;
    padding: 0;
    color: white;
}

.MuiInputBase-input {
    color: white;
}
