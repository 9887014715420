HTML CSSResult Skip Results Iframe EDIT ON .menu {
    max-width: 600px;
}

.menu a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
}

label {
    display: block;
    margin: 0 0 4px 0;
    line-height: 1.3;

    cursor: pointer;
}

input {
    display: none;
}

.menu ul {
    margin: 0;
    padding: 0;
    background: #f4f4f4;
    list-style: none;
}

.menu li {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#menu_bar01:checked ~ #links01 li {
    padding: 6px;
    height: 20px;
    font-size: 9pt;
    opacity: 1;
}

#name_icon {
    font-size: 10pt;
    padding: 4px;
    color: #ffffff;
    border-radius: 50%;
    background-color: lightsteelblue;
}
