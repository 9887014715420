@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.textlink {
    color: #75a9ff;
    border-bottom: dashed 1px #75a9ff;
    cursor: pointer;
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scrolling-wrapper .item {
    display: inline-block;
}

.App {
    text-align: center;
}

main.main-style {
    background-color: #27272efd;
    height: 1200px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#footer {
    position: fixed;
    display: 'flex';
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    text-align: 'center';
    background-color: #020f1b;
    z-index: 1000;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    max-height: 50px;
}

.mock {
    color: #dae0e0;
}

#headerBar {
    top: 0px;
    width: 100%;
    background-color: #020f1b;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

#appName {
    text-align: center;
    font-weight: bolder;

    color: aliceblue;
    margin: 0;
    padding: 3px;
}

#mypage {
    display: table-cell;
    position: absolute;
    right: 0;
    top: 6px;
    margin: 0;
}

.popup-menu {
    position: absolute;
    z-index: 2;

    width: 10rem;
    margin-top: 0.5rem;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;
}

#accountTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#accountTable th {
    width: 30%;
    font-size: 10pt;
    border-right: 3px solid rgb(234, 245, 255);
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    color: #dae0e0;
    font-weight: bold;
}
#accountTable td {
    width: 50%;
    color: #dae0e0;
    font-weight: bold;
    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}

#timeTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#timeTable th {
    width: 40%;
    font-size: 10pt;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    color: #fff;
}
#timeTable td {
    width: 50%;
    color: #fff;

    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}

#centeredWrapper {
    text-align: 'center';
}

#linkedText {
    color: #bec8e9;
    text-decoration: underline;
}

label.upload_inputwrapper__1W9EP {
    background: #1e90ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
    font-weight: bolder;
}
label input {
    display: none;
}

#upload_white-char__23_3N {
    font-size: 12pt;
    color: rgba(255, 255, 255, 0.842);
}

.upload_MuiSelect-select__1UTfy.upload_MuiSelect-select__1UTfy {
    color: aliceblue;
}

.upload_MuiSelect-nativeInput__1QpgD {
    color: aliceblue;
}

#upload_upload-background__1rnDn {
    background-color: rgb(56, 56, 71);
}

.upload_MuiInputBase-inputMultiline__27Txl {
    height: auto;
    resize: none;
    padding: 0;
    color: white;
}

.upload_MuiInputBase-input__35N16 {
    color: white;
}

#white-char {
    color: rgba(255, 255, 255, 0.815);
    font-weight: bold;
    font-size: 8pt;
}

#blurred {
    box-shadow: 5px 5px 5px 0 rgba(201, 201, 201, 0.522);
}

#videoCard {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    width: 90%;
    text-align: center;
    padding: 7px;
    padding-bottom: 3px;
    margin-top: 3px;
    margin-left: 10px;
    border-bottom: solid 2px #9ba2a7;
}
#videoCard:hover {
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px) translateX(-8px);
}

.text-image {
    position: relative;
}

.text-image img {
    width: 100%;
}

.text-image p {
    position: absolute;
    top: 38%;
    left: 17%;
    font-size: 30px;
    color: #000080;
}

#videoBasic {
    margin-top: 30px;
    width: 90%;
    height: 45%;
}

#detailTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#detailTable th {
    width: 45%;
    font-size: 9pt;
    border-right: 3px solid rgb(253, 253, 253);
    border-top: 1px solid rgb(97, 149, 201);
    border-bottom: 1px solid rgb(97, 149, 201);
    padding: 6px;
    color: white;
}
#detailTable td {
    width: 45%;
    font-size: 10pt;
    border-top: 1px solid rgb(97, 149, 201);
    border-bottom: 1px solid rgb(97, 149, 201);
    padding: 6px;
    color: white;
}

#timeChar {
    font-family: 'Courgette', cursive;
    font-size: 30pt;
    color: white;
    border-bottom: solid 1px #66cdaa;
}

.text-image {
    position: relative;
}

.text-image img {
    width: 100%;
}

.text-image p {
    position: absolute;
    top: 38%;
    left: 17%;
    font-size: 30px;
    color: #000080;
}

#videoBasic {
    margin-top: 30px;
    width: 80%;
    height: 40%;
}

/* .text-image p #stride{
  position: absolute;
  top: 40%;
  left: 35%;
  font-size: 30px;
  color: #000080;
} */

.recharts-surface {
    background-color: #022130;
}

label.register_inputwrapper__3M9Dx {
    background: #1e90ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
    font-weight: bolder;
}
label input {
    display: none;
}

#register_white-char__2zXoN {
    font-size: 12pt;
    color: rgba(255, 255, 255, 0.842);
}

.register_select__tCzNW {
    color: rgb(61, 63, 65);
}

.register_MuiSelect-nativeInput__1-Vt2 {
    color: aliceblue;
}

.register_MuiInputBase-inputMultiline__3Wel6 {
    height: auto;
    resize: none;
    padding: 0;
    color: white;
}

.register_MuiInputBase-input__3NEER {
    color: white;
}

.fab {
    position: fixed; /* 今の位置を基準 */
    top: 80%; /* 上から10px */
    right: 8%; /* 左から20px */
}

HTML CSSResult Skip Results Iframe EDIT ON .menu {
    max-width: 600px;
}

.menu a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
}

label {
    display: block;
    margin: 0 0 4px 0;
    line-height: 1.3;

    cursor: pointer;
}

input {
    display: none;
}

.menu ul {
    margin: 0;
    padding: 0;
    background: #f4f4f4;
    list-style: none;
}

.menu li {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
}

#menu_bar01:checked ~ #links01 li {
    padding: 6px;
    height: 20px;
    font-size: 9pt;
    opacity: 1;
}

#name_icon {
    font-size: 10pt;
    padding: 4px;
    color: #ffffff;
    border-radius: 50%;
    background-color: lightsteelblue;
}

